import revive_payload_client_YTGlmQWWT5 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/be.unity.org/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_j3xPeZV1re from "/vercel/path0/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_SKRzdyvE3U from "/vercel/path0/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_2yIKADAg6h from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import motion_ubMvl82f6K from "/vercel/path0/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/apps/be.unity.org/.nuxt/pwa-icons-plugin.ts";
import pwa_client_V4UTG2qgGR from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_G4IIxjdyW8 from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_dOTnCc6TUQ from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_8brYH9QEed from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_aFv0MENOr7 from "/vercel/path0/packages/sentry/plugins/sentry.client.ts";
import notifications_T86GuSS23r from "/vercel/path0/packages/notifications/app/plugins/notifications.ts";
import subscriptions_141dewn1PR from "/vercel/path0/packages/notifications/app/plugins/subscriptions.ts";
import favorites_dqsFXPnVpx from "/vercel/path0/packages/favorites/plugins/favorites.ts";
import color_scheme_9hAm0J9L0P from "/vercel/path0/apps/be.unity.org/app/plugins/color-scheme.ts";
import i18n_KYRD8MIJaU from "/vercel/path0/apps/be.unity.org/app/plugins/i18n.ts";
import readability_oDil4Rlzqe from "/vercel/path0/apps/be.unity.org/app/plugins/readability.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_j3xPeZV1re,
  plugin_SKRzdyvE3U,
  plugin_2yIKADAg6h,
  motion_ubMvl82f6K,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_V4UTG2qgGR,
  switch_locale_path_ssr_G4IIxjdyW8,
  i18n_dOTnCc6TUQ,
  plugin_8brYH9QEed,
  sentry_client_aFv0MENOr7,
  notifications_T86GuSS23r,
  subscriptions_141dewn1PR,
  favorites_dqsFXPnVpx,
  color_scheme_9hAm0J9L0P,
  i18n_KYRD8MIJaU,
  readability_oDil4Rlzqe
]