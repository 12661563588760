import { createFavoritesCollection, createSyncManager } from '../lib/favorites'

export default defineNuxtPlugin(() => {
  if (import.meta.server) {
    return
  }

  const collection = createFavoritesCollection()
  const syncManager = createSyncManager()
  syncManager.addCollection(collection, {
    name: 'favorites',
  })

  const { status, isAuthenticated } = useAuthLinks()

  watch(status, (newVal, oldVal) => {
    console.debug(
      `[$favorites] auth status changed from ${oldVal} to ${newVal}`,
      { status: unref(status), isAuthenticated: unref(isAuthenticated) },
    )

    if (newVal === 'authenticated' && oldVal !== 'authenticated') {
      console.log('[$favorites] authenticated, syncing with backend')
      syncManager.sync('favorites')
    }
  })

  return {
    provide: {
      favorites: () => ({
        collection,
        syncManager,
      }),
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $favorites(): {
      collection: ReturnType<typeof createFavoritesCollection>
      syncManager: ReturnType<typeof createSyncManager>
    }
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $favorites(): {
      collection: ReturnType<typeof createFavoritesCollection>
      syncManager: ReturnType<typeof createSyncManager>
    }
  }
}
