<script setup lang="ts">
const { t } = useI18n()

const props = defineProps({
  version: {
    type: String,
    default: '',
  },
  statusCode: {
    type: Number,
    default: 500,
  },
  statusMessage: {
    type: String,
    default: 'Server error',
  },
  description: {
    type: String,
    default: 'This page is temporarily unavailable.',
  },
  cause: {
    type: String,
    default: 'server-error',
  },
  url: {
    type: String,
    default: '',
  },
})

const cause = computed(() => {
  if (!props?.cause) {
    console.warn('[error] cause not provided:', props)
    return 'server-error'
  }

  if (typeof props.cause !== 'string') {
    console.warn('[error] cause is not a string:', props.cause)
    return 'server-error'
  }

  return props.cause
})

const url = computed(() => {
  if (import.meta.client) {
    return window.location.href
  }
  return props.url
})
</script>

<template>
  <ErrorPage :title="`${statusCode}`" :description="description">
    <template #title>{{ t(`${cause}.heading`) }}</template>
    <template #description>
      {{ t(`${cause}.description`) }}
    </template>
  </ErrorPage>
</template>

<i18n lang="json">
{
  "en": {
    "empty-data-response": {
      "heading": "Page is temporarily unavailable.",
      "description": "Please try again later."
    },
    "server-error": {
      "heading": "Page is temporarily unavailable.",
      "description": "Please try again later."
    }
  },
  "es": {
    "empty-data-response": {
      "heading": "La página no está disponible temporalmente.",
      "description": "Inténtelo de nuevo más tarde."
    },
    "server-error": {
      "heading": "La página no está disponible temporalmente.",
      "description": "Inténtelo de nuevo más tarde."
    }
  }
}
</i18n>
