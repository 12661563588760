<template>
  <div class="flex flex-col place-content-start min-h-screen overflow-hidden">
    <div class="spotlight fixed left-0 right-0 z-10"></div>
    <div
      class="grid place-content-center flex-grow flex-shrink-0 basis-full font-sans antialiased"
    >
      <div
        class="max-w-[520px] text-center text-brand-midnight z-20 dark:text-white"
      >
        <h1
          class="text-4xl lg:text-8xl leading-none sm:text-[7rem] font-medium mb-8"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h1>
        <p
          class="text-xl px-8 sm:px-0 sm:text-4xl font-light mb-16 leading-tight"
        >
          <slot name="description">
            {{ description }}
          </slot>
        </p>

        <div class="w-full flex items-center justify-center">
          <button
            class="gradient-border text-md sm:text-xl py-2 px-4 sm:py-3 sm:px-6 cursor-pointer"
            @click="goHome()"
          >
            <slot name="action">
              {{ action || t('Back to BeUnity') }}
            </slot>
          </button>
        </div>
      </div>
    </div>

    <footer class="container">
      <p
        class="inline-flex flex-row gap-2 py-2 font-montserrat italic text-xs dark:text-gray-200"
      >
        <span>BeUnity App</span>
        <span v-if="buildVersion">| Version: {{ buildVersion }}</span>
        <span v-if="buildId">Build: {{ buildId }}</span>
      </p>
    </footer>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()

const props = defineProps<{
  title: string
  description: string
  action?: string
}>()

const {
  public: { build },
} = useRuntimeConfig()
const buildVersion = computed(() => build?.version)
const buildId = computed(() => build.id)

const goHome = () => {
  return clearError({
    redirect: '/',
  })
}
</script>

<style lang="postcss" scoped>
.spotlight {
  background: linear-gradient(45deg, #00dc82 0%, #36e4da 50%, #0047e1 100%);
  background: linear-gradient(
    45deg,
    theme(colors.brand.cornflower) 0%,
    theme(colors.brand.peacock) 50%,
    theme(colors.brand.midnight) 100%
  );
  filter: blur(20vh);
  height: 40vh;
  bottom: -30vh;
}

.gradient-border {
  position: relative;
  border-radius: 0.5rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  @media (prefers-color-scheme: light) {
    background-color: rgba(255, 255, 255, 0.3);

    &::before {
      background: linear-gradient(
        90deg,
        #e2e2e2 0%,
        #e2e2e2 25%,
        #00dc82 50%,
        #36e4da 75%,
        #0047e1 100%
      );
      background: linear-gradient(
        90deg,
        theme(colors.brand.sky/50) 0%,
        theme(colors.brand.sky) 25%,
        theme(colors.brand.peacock/75) 50%,
        theme(colors.brand.peacock) 75%,
        theme(colors.brand.midnight) 100%
      );
    }
  }

  @media (prefers-color-scheme: dark) {
    background-color: rgba(20, 20, 20, 0.3);

    &::before {
      background: linear-gradient(
        90deg,
        #303030 0%,
        #303030 25%,
        #00dc82 50%,
        #36e4da 75%,
        #0047e1 100%
      );
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.5rem;
    padding: 2px;
    width: 100%;
    background-size: 400% auto;
    opacity: 0.5;
    transition: background-position 0.3s ease-in-out, opacity 0.2s ease-in-out;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover::before {
    background-position: -50% 0;
    opacity: 1;
  }
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol, 'Noto Color Emoji';
}
</style>
