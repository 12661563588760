// import { createSyncManager } from '../../lib/sync-manager'
import { createNotificationsCollection } from '../../lib/notifications'

export default defineNuxtPlugin(() => {
  if (import.meta.server) {
    return
  }

  const collection = createNotificationsCollection()
  // const syncManager = createSyncManager()
  // syncManager.addCollection(collection, {
  //   name: 'notifications',
  // })

  // const { status, isAuthenticated } = useAuthLinks()
  // watch(status, (newVal, oldVal) => {
  //   console.debug(
  //     `[$notifications] auth status changed from ${oldVal} to ${newVal}`,
  //     { status: unref(status), isAuthenticated: unref(isAuthenticated) },
  //   )

  //   if (newVal === 'authenticated' && oldVal !== 'authenticated') {
  //     console.log('[$notifications] authenticated, syncing with backend')
  //     // syncManager.sync('notifications')
  //   }
  // })

  return {
    provide: {
      notifications: () => ({
        collection,
        // syncManager,
      }),
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $notifications(): {
      collection: ReturnType<typeof createNotificationsCollection>
      // syncManager: ReturnType<typeof createSyncManager>
    }
  }
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $notifications(): {
      collection: ReturnType<typeof createNotificationsCollection>
      // syncManager: ReturnType<typeof createSyncManager>
    }
  }
}
