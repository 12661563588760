import { createLocalStorageAdapter, Collection } from 'signaldb'
import reactivityAdapter from 'signaldb-plugin-vue'
import type { UserNotification } from '../../shared/types'

export const createNotificationsCollection = () => {
  const NotificationsCollection = new Collection<UserNotification>({
    persistence: createLocalStorageAdapter('notifications'),
    reactivity: reactivityAdapter,
  })

  NotificationsCollection.on('persistence.init', () => {
    console.debug('[notifications.collection] persistence init')
  })

  NotificationsCollection.on('persistence.error', (error) => {
    console.error('[notifications.collection] persistence error', error)
  })

  return NotificationsCollection
}
