<template>
  <NuxtPwaManifest />
  <NuxtPwaAssets />
  <NuxtLoadingIndicator />
  <div id="top" class="app-container">
    <Transition name="fade">
      <Splash v-if="show" />
      <NuxtLayout v-else>
        <NuxtPage />
      </NuxtLayout>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'

const route = useRoute()

const splash = route?.query?.splash === 'show'

// @todo: move to app.config
const duration = ref<number>(5000)

const show = ref(splash)
const showSplash = async () => {
  show.value = true
  await promiseTimeout(unref(duration))
  show.value = false
}

onMounted(async () => {
  if (splash) showSplash()
})

const i18nHead = useLocaleHead()
useHead({
  titleTemplate: '%s %separator %siteName',
  templateParams: { separator: '|', siteName: 'BeUnity' },
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang || 'en',
  },
  link: [
    ...(i18nHead.value.link || []),
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
  ],
  meta: [...(i18nHead.value.meta || [])],
})
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  translate: 0;
  opacity: 1;
}

.page-enter-from {
  translate: 100vw;
  opacity: 0;
}

.page-leave-to {
  translate: -100vw;
  opacity: 0;
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
  opacity: 1;
}
.layout-enter-from,
.layout-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
