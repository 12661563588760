import { SyncManager } from 'signaldb'
import reactivityAdapter from 'signaldb-plugin-vue'
// import createSsrLocalStorageAdapter from './persistance'
import type { Favorite } from '../../lib/favorites'

export const createSyncManager = () => {
  return new SyncManager<{ name: string }, Favorite>({
    // persistenceAdapter: createSsrLocalStorageAdapter,
    reactivity: reactivityAdapter,
    onError: (_options, error) => {
      console.error('[SyncManager] error', error)
    },
    pull: async ({ name }, { lastFinishedSyncEnd, lastFinishedSyncStart }) => {
      const headers = useRequestHeaders(['cookie']) as HeadersInit
      console.log(
        `[SyncManager] pulling changes since ${lastFinishedSyncEnd}`,
        { lastFinishedSyncStart },
      )

      const { documents } = await $fetch(`/api/collections/${name}/documents`, {
        query: {
          since: lastFinishedSyncEnd,
        },
        headers,
      })

      const items: Favorite[] = documents.map((item) => ({
        id: item.id,
        uuid: item.uuid,
        title: item.title,
        date: new Date(item.date),
        path: item.path,
        added: new Date(item.added),
        userId: item.userId,
      }))

      return { items }
    },
    push: async ({ name }, { changes }) => {
      const headers = useRequestHeaders(['cookie']) as HeadersInit
      if (changes.added.length > 0) {
        console.log(
          `[SyncManager] adding ${changes.added.length} items to backend`,
        )
        await $fetch(`/api/collections/${name}/documents`, {
          method: 'POST',
          body: {
            items: changes.added,
          },
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
        })
      }

      if (changes.modified.length > 0) {
        console.log(
          `[SyncManager] updating ${changes.modified.length} items to backend`,
        )
        await $fetch(`/api/collections/${name}/documents`, {
          method: 'PATCH',
          body: {
            items: changes.modified,
          },
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
        })
      }

      if (changes.removed.length > 0) {
        console.log(
          `[SyncManager] removing ${changes.added.length} items from backend`,
        )
        await $fetch(`/api/collections/${name}/documents`, {
          method: 'DELETE',
          body: {
            items: changes.removed,
          },
          headers: {
            ...headers,
            'Content-Type': 'application/json',
          },
        })
      }
    },
  })
}
