import { createIndex, createLocalStorageAdapter, Collection } from 'signaldb'
import reactivityAdapter from 'signaldb-plugin-vue'
import type { Favorite } from './types'

export const createFavoritesCollection = () => {
  const FavoritesCollection = new Collection<Favorite>({
    persistence: createLocalStorageAdapter('favorites'),
    reactivity: reactivityAdapter,
    indices: [createIndex('uuid'), createIndex('title'), createIndex('added')],
  })

  FavoritesCollection.on('persistence.error', (error) => {
    console.error('[favorites.collection] persistence error', error)
  })

  return FavoritesCollection
}
