import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import previous_45path_45global from "/vercel/path0/apps/be.unity.org/app/middleware/previous-path.global.ts";
import start_45url_45global from "/vercel/path0/apps/be.unity.org/app/middleware/start-url.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  previous_45path_45global,
  start_45url_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "cache-control": () => import("/vercel/path0/apps/be.unity.org/app/middleware/cache-control.ts"),
  auth: () => import("/vercel/path0/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}