export default defineNuxtRouteMiddleware((to, from) => {
  if (to.path !== '/') {
    return
  }
  const start_url = useCookie('start_url')

  console.log(`Redirecting user to start url "${unref(start_url)}"`, {
    to,
    from,
  })
  console.debug('Start URL Middleware', {
    to,
    from,
    start_url: unref(start_url),
  })

  return navigateTo({ path: unref(start_url) || '/prayer', query: to.query })
})
