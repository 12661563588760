export const ColorSchemes = {
  LIGHT: 'light',
  DARK: 'dark',
  AUTO: 'auto',
  CONTRAST: 'contrast',
} as const

export type ColorSchemeKey = keyof typeof ColorSchemes
export type ColorScheme = (typeof ColorSchemes)[keyof typeof ColorSchemes]

export function useAvailableColorSchemes() {
  const { t } = useI18n()

  type ThemeDef = {
    readonly key: 'light' | 'dark' | 'auto'
    readonly icon: string
  }

  const { themes } = useAppConfig() as { themes: readonly ThemeDef[] }

  const preferredDark = usePreferredDark()
  const preferredMode = computed(() => (preferredDark.value ? 'dark' : 'light'))

  const modes = computed(() => themes.map(({ key }) => key))

  const available = computed(() => {
    return themes.map(({ key: _key, icon }) => {
      const key = _key.toUpperCase() as keyof typeof ColorSchemes

      const theme = _key === 'auto' ? preferredMode.value : _key

      return {
        key,
        icon,
        theme,
        mode: _key,
        label: t(`theme.${_key}.label`),
        description: t(`theme.${_key}.description`),
      }
    })
  })

  return { modes, available, data: available }
}

export function useColorScheme() {
  const cookie = useCookie('pref:color-scheme', {
    default: () => 'auto',
  })

  const { system, store } = useColorMode({
    storageRef: cookie,
    attribute: 'data-theme',
  })

  const theme = computed(() =>
    store.value === 'auto' ? system.value : store.value,
  )

  const isDarkMode = computed(() => theme.value === 'dark')

  return {
    cookie,
    mode: store,
    theme,
    colorMode: store,
    colorScheme: theme,
    isDarkMode,
  }
}
