export default defineNuxtPlugin(() => {
  // Readability
  const { cookie: readability } = useReadabilityPreference()

  useHead({
    htmlAttrs: {
      'data-readability': readability,
    },
  })
})
