import { createLocalStorageAdapter, Collection } from 'signaldb'
import reactivityAdapter from 'signaldb-plugin-vue'
import type { UserSubscription } from '../../shared/types'

export const createSubscriptionsCollection = () => {
  const { subscribable: channels } = useSubscriptionChannels()

  const collection = new Collection<UserSubscription>({
    persistence: createLocalStorageAdapter('subscriptions'),
    reactivity: reactivityAdapter,
  })

  collection.on('persistence.init', () => {
    const userPreferences = collection.find().fetch()

    const subscribable = unref(channels)
      .filter(({ id }) => !userPreferences.some((p) => p.channelId === id))
      .map(({ id: channelId, isActive }) => ({
        channelId,
        isActive,
        createdAt: new Date(),
        updatedAt: new Date(),
      }))

    collection.insertMany(subscribable)

    console.debug('[subscriptions.collection] persistence init', {
      subscribable,
    })

    if (subscribable.length) {
      console.info(
        `[subscriptions.collection] Inserting ${subscribable.length} new subscription channels`,
      )
    }
  })

  collection.on('persistence.error', (error) => {
    console.error('[subscriptions.collection] persistence error', error)
  })

  return collection
}
