import type { NotificationChannel } from '../shared/types'

export default defineAppConfig({
  notifications: {
    channels: [
      {
        id: 'app_updates',
        isSubscribable: false,
        isForced: true,
        isActive: true,
      },
      {
        id: 'daily_word_magazine',
        isSubscribable: true,
        isForced: false,
        isActive: true,
      },
      {
        id: 'spirituality_health_magazine',
        isSubscribable: true,
        isForced: false,
        isActive: true,
      },
      {
        id: 'unity_magazine',
        isSubscribable: true,
        isForced: false,
        isActive: true,
      },
    ],
  },
})

declare module '@nuxt/schema' {
  interface AppConfig {
    notifications: {
      channels: NotificationChannel[]
    }
  }

  interface AppConfigInput {
    notifications?: {
      channels: NotificationChannel[]
    }
  }
}
