export default defineAppConfig({
  favorites: {
    name: 'Hello from Favorites layer',
  },
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    favorites?: {
      /** Project name */
      name?: string
    }
  }
}
