import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconCandle, LazyIconDW, LazyIconMeditate, LazyIconSpectrum, LazyIconTypeReadable, LazyIconTypeStandard, LazySplash, LazyUnityLogo, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["IconCandle", LazyIconCandle],
["IconDW", LazyIconDW],
["IconMeditate", LazyIconMeditate],
["IconSpectrum", LazyIconSpectrum],
["IconTypeReadable", LazyIconTypeReadable],
["IconTypeStandard", LazyIconTypeStandard],
["Splash", LazySplash],
["UnityLogo", LazyUnityLogo],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
