import { createEventHook, useLastChanged, type EventHookOn } from '@vueuse/core'
import { get } from 'radash'
import type {
  NotificationChannel,
  UserSubscription as UserSubscriptionType,
} from '../../shared/types'

type UserSubscription = Omit<UserSubscriptionType, 'id'>

export function useSubscriptionChannels() {
  const appConfig = useAppConfig()
  const notificationChannels = get<NotificationChannel[]>(
    appConfig,
    'notifications.channels',
    [],
  )

  const subscribable = computed(() => {
    return notificationChannels.filter(({ isSubscribable }) => isSubscribable)
  })

  const forced = computed(() => {
    return notificationChannels.filter(({ isForced }) => isForced)
  })

  const channelIsSubscribable = (channelId: string) => {
    return unref(subscribable).some(({ id }) => id === channelId)
  }

  const channelIsForced = (channelId: string) => {
    return unref(forced).some(({ id }) => id === channelId)
  }

  return {
    data: notificationChannels,
    subscribable,
    forced,
    channelIsSubscribable,
    channelIsForced,
  }
}

export function useSubscriptions() {
  const collectionAdd = createEventHook<UserSubscription[]>()
  const collectionRemove = createEventHook()

  if (import.meta.server) {
    return useSubscriptionsStub({
      onAdded: collectionAdd.on,
      onRemoved: collectionRemove.on,
    })
  }

  const { data: channels, channelIsSubscribable } = useSubscriptionChannels()

  const { $subscriptions } = useNuxtApp()
  const { collection } = $subscriptions()

  const items = computed(() => {
    const userPreferences = collection.find().fetch()

    return unref(channels).map(
      ({ id: channelId, isForced, isSubscribable, isActive }) => {
        const prefs = userPreferences.find((p) => p.channelId === channelId)
        return {
          channelId,
          isForced,
          isSubscribable,
          isActive,
          ...prefs,
        }
      },
    )
  })
  const lastChanged = useLastChanged(items, { deep: true })

  const canModify = (channel: string) => {
    const { isSubscribable = true } =
      unref(channels).find(({ id }) => id === channel) || {}
    return isSubscribable
  }

  const subscribeTo = (channel: string) => {
    if (!channelIsSubscribable(channel)) {
      console.warn(`Cannot modify subscription ${channel}`)
      return
    }
    console.log(`Subscribing to ${channel}`)
    collection.updateOne(
      { channelId: channel },
      { $set: { isActive: true, updatedAt: new Date() } },
    )
  }

  const unSubscribeFrom = (channel: string) => {
    if (!channelIsSubscribable(channel)) {
      console.warn(`Cannot modify subscription ${channel}`)
      return
    }

    console.log(`Unsubscribing from ${channel}`)
    collection.updateOne(
      { channelId: channel },
      { $set: { isActive: false, updatedAt: new Date() } },
    )
  }

  return {
    data: items,
    items,
    lastChanged,
    channels,
    canModifySubscription: canModify,
    subscribeTo,
    unSubscribeFrom,
    onAdded: collectionAdd.on,
    onRemoved: collectionRemove.on,
  }
}

type UserSubscriptionsReturn = {
  onAdded: EventHookOn
  onRemoved: EventHookOn
}

function useSubscriptionsStub(defaults: UserSubscriptionsReturn) {
  type ItemType = NotificationChannel & UserSubscription
  const items = ref<ItemType[]>([])
  const lastChanged = ref(new Date())

  const canModify = (_channel: string) => false
  const subscribeTo = (_channel: string) => void 0
  const unSubscribeFrom = (_channel: string) => void 0

  return {
    ...defaults,
    data: items,
    items,
    lastChanged,
    canModifySubscription: canModify,
    subscribeTo,
    unSubscribeFrom,
  }
}
