export const useReadabilityPreference = () => {
  const state = useLocalStorage('pref:readability', 'standard')
  const cookie = useCookie('pref:readability', {
    default: () => state.value,
  })

  watch(state, (newVal) => {
    cookie.value = newVal
  })

  return {
    data: state,
    cookie,
  }
}
