import { default as errorpqIc6j4wPnMeta } from "/vercel/path0/apps/be.unity.org/app/pages/auth/error.vue?macro=true";
import { default as _91date_93eASNDuu98uMeta } from "/vercel/path0/apps/be.unity.org/app/pages/daily-word/[date].vue?macro=true";
import { default as indext54mMdWAUqMeta } from "/vercel/path0/apps/be.unity.org/app/pages/daily-word/index.ts?macro=true";
import { default as pick1upB2n4ZqNMeta } from "/vercel/path0/apps/be.unity.org/app/pages/daily-word/pick.vue?macro=true";
import { default as favoritesGCnFfOdRaxMeta } from "/vercel/path0/apps/be.unity.org/app/pages/favorites.vue?macro=true";
import { default as installcklQV6XYmNMeta } from "/vercel/path0/apps/be.unity.org/app/pages/help/install.vue?macro=true";
import { default as indexkRqjBMEMSzMeta } from "/vercel/path0/apps/be.unity.org/app/pages/index.ts?macro=true";
import { default as _91name_93MMVn9HpMRjMeta } from "/vercel/path0/apps/be.unity.org/app/pages/playlist/[name].vue?macro=true";
import { default as indexwGw3RiaP98Meta } from "/vercel/path0/apps/be.unity.org/app/pages/prayer/index.vue?macro=true";
import { default as submitzrQNoM5CwyMeta } from "/vercel/path0/apps/be.unity.org/app/pages/prayer/submit.vue?macro=true";
import { default as thank_45youTHQp41iTQMMeta } from "/vercel/path0/apps/be.unity.org/app/pages/prayer/thank-you.vue?macro=true";
import { default as preferencesVAP5jFF99WMeta } from "/vercel/path0/apps/be.unity.org/app/pages/preferences.vue?macro=true";
import { default as start3kQik877JGMeta } from "/vercel/path0/apps/be.unity.org/app/pages/start.ts?macro=true";
import { default as authAy7fEixZkvMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/auth.vue?macro=true";
import { default as colorsXjsgpxF6USMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/colors.vue?macro=true";
import { default as date_45inputieJ1Zx0augMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/date-input.vue?macro=true";
import { default as infinite_45scroll3DzfYj2Z5rMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/infinite-scroll.vue?macro=true";
import { default as jsonapiZxUKdZkN5RMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/jsonapi.vue?macro=true";
import { default as notificationsD1xObE4IlWMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/notifications.vue?macro=true";
import { default as splashe2VcRLohCoMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/splash.vue?macro=true";
import { default as subscriptionsaPxhT3o6pXMeta } from "/vercel/path0/apps/be.unity.org/app/pages/tester/subscriptions.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth-error___en___default",
    path: "/auth/error",
    meta: errorpqIc6j4wPnMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: "auth-error___en",
    path: "/en/auth/error",
    meta: errorpqIc6j4wPnMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: "auth-error___es",
    path: "/es/auth/error",
    meta: errorpqIc6j4wPnMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: "daily-word-date___en___default",
    path: "/daily-word/:date()",
    meta: _91date_93eASNDuu98uMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/[date].vue").then(m => m.default || m)
  },
  {
    name: "daily-word-date___en",
    path: "/en/daily-word/:date()",
    meta: _91date_93eASNDuu98uMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/[date].vue").then(m => m.default || m)
  },
  {
    name: "daily-word-date___es",
    path: "/es/la-palabra-diaria/:date()",
    meta: _91date_93eASNDuu98uMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/[date].vue").then(m => m.default || m)
  },
  {
    name: "daily-word___en___default",
    path: "/daily-word",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/index.ts").then(m => m.default || m)
  },
  {
    name: "daily-word___en",
    path: "/en/daily-word",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/index.ts").then(m => m.default || m)
  },
  {
    name: "daily-word___es",
    path: "/es/daily-word",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/index.ts").then(m => m.default || m)
  },
  {
    name: "daily-word-pick___en___default",
    path: "/daily-word/pick",
    meta: pick1upB2n4ZqNMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/pick.vue").then(m => m.default || m)
  },
  {
    name: "daily-word-pick___en",
    path: "/en/daily-word/pick",
    meta: pick1upB2n4ZqNMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/pick.vue").then(m => m.default || m)
  },
  {
    name: "daily-word-pick___es",
    path: "/es/daily-word/pick",
    meta: pick1upB2n4ZqNMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/daily-word/pick.vue").then(m => m.default || m)
  },
  {
    name: "favorites___en___default",
    path: "/favorites",
    meta: favoritesGCnFfOdRaxMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites___en",
    path: "/en/favorites",
    meta: favoritesGCnFfOdRaxMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites___es",
    path: "/es/favorites",
    meta: favoritesGCnFfOdRaxMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/favorites.vue").then(m => m.default || m)
  },
  {
    name: "help-install___en___default",
    path: "/help/install",
    meta: installcklQV6XYmNMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/help/install.vue").then(m => m.default || m)
  },
  {
    name: "help-install___en",
    path: "/en/help/install",
    meta: installcklQV6XYmNMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/help/install.vue").then(m => m.default || m)
  },
  {
    name: "help-install___es",
    path: "/es/help/install",
    meta: installcklQV6XYmNMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/help/install.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/index.ts").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/index.ts").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/index.ts").then(m => m.default || m)
  },
  {
    name: "playlist-name___en___default",
    path: "/playlist/:name()",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/playlist/[name].vue").then(m => m.default || m)
  },
  {
    name: "playlist-name___en",
    path: "/en/playlist/:name()",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/playlist/[name].vue").then(m => m.default || m)
  },
  {
    name: "playlist-name___es",
    path: "/es/playlist/:name()",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/playlist/[name].vue").then(m => m.default || m)
  },
  {
    name: "prayer___en___default",
    path: "/prayer",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/index.vue").then(m => m.default || m)
  },
  {
    name: "prayer___en",
    path: "/en/prayer",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/index.vue").then(m => m.default || m)
  },
  {
    name: "prayer___es",
    path: "/es/prayer",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/index.vue").then(m => m.default || m)
  },
  {
    name: "prayer-submit___en___default",
    path: "/prayer/submit",
    meta: submitzrQNoM5CwyMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/submit.vue").then(m => m.default || m)
  },
  {
    name: "prayer-submit___en",
    path: "/en/prayer/submit",
    meta: submitzrQNoM5CwyMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/submit.vue").then(m => m.default || m)
  },
  {
    name: "prayer-submit___es",
    path: "/es/prayer/submit",
    meta: submitzrQNoM5CwyMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/submit.vue").then(m => m.default || m)
  },
  {
    name: "prayer-thank-you___en___default",
    path: "/prayer/thank-you",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "prayer-thank-you___en",
    path: "/en/prayer/thank-you",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "prayer-thank-you___es",
    path: "/es/prayer/thank-you",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/prayer/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "preferences___en___default",
    path: "/preferences",
    meta: preferencesVAP5jFF99WMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/preferences.vue").then(m => m.default || m)
  },
  {
    name: "preferences___en",
    path: "/en/preferences",
    meta: preferencesVAP5jFF99WMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/preferences.vue").then(m => m.default || m)
  },
  {
    name: "preferences___es",
    path: "/es/preferences",
    meta: preferencesVAP5jFF99WMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/preferences.vue").then(m => m.default || m)
  },
  {
    name: "start___en___default",
    path: "/start",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/start.ts").then(m => m.default || m)
  },
  {
    name: "start___en",
    path: "/en/start",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/start.ts").then(m => m.default || m)
  },
  {
    name: "start___es",
    path: "/es/start",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/start.ts").then(m => m.default || m)
  },
  {
    name: "tester-auth___en___default",
    path: "/tester/auth",
    meta: authAy7fEixZkvMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/auth.vue").then(m => m.default || m)
  },
  {
    name: "tester-auth___en",
    path: "/en/tester/auth",
    meta: authAy7fEixZkvMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/auth.vue").then(m => m.default || m)
  },
  {
    name: "tester-auth___es",
    path: "/es/tester/auth",
    meta: authAy7fEixZkvMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/auth.vue").then(m => m.default || m)
  },
  {
    name: "tester-colors___en___default",
    path: "/tester/colors",
    meta: colorsXjsgpxF6USMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/colors.vue").then(m => m.default || m)
  },
  {
    name: "tester-colors___en",
    path: "/en/tester/colors",
    meta: colorsXjsgpxF6USMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/colors.vue").then(m => m.default || m)
  },
  {
    name: "tester-colors___es",
    path: "/es/tester/colors",
    meta: colorsXjsgpxF6USMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/colors.vue").then(m => m.default || m)
  },
  {
    name: "tester-date-input___en___default",
    path: "/tester/date-input",
    meta: date_45inputieJ1Zx0augMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/date-input.vue").then(m => m.default || m)
  },
  {
    name: "tester-date-input___en",
    path: "/en/tester/date-input",
    meta: date_45inputieJ1Zx0augMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/date-input.vue").then(m => m.default || m)
  },
  {
    name: "tester-date-input___es",
    path: "/es/tester/date-input",
    meta: date_45inputieJ1Zx0augMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/date-input.vue").then(m => m.default || m)
  },
  {
    name: "tester-infinite-scroll___en___default",
    path: "/tester/infinite-scroll",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/infinite-scroll.vue").then(m => m.default || m)
  },
  {
    name: "tester-infinite-scroll___en",
    path: "/en/tester/infinite-scroll",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/infinite-scroll.vue").then(m => m.default || m)
  },
  {
    name: "tester-infinite-scroll___es",
    path: "/es/tester/infinite-scroll",
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/infinite-scroll.vue").then(m => m.default || m)
  },
  {
    name: "tester-jsonapi___en___default",
    path: "/tester/jsonapi",
    meta: jsonapiZxUKdZkN5RMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/jsonapi.vue").then(m => m.default || m)
  },
  {
    name: "tester-jsonapi___en",
    path: "/en/tester/jsonapi",
    meta: jsonapiZxUKdZkN5RMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/jsonapi.vue").then(m => m.default || m)
  },
  {
    name: "tester-jsonapi___es",
    path: "/es/tester/jsonapi",
    meta: jsonapiZxUKdZkN5RMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/jsonapi.vue").then(m => m.default || m)
  },
  {
    name: "tester-notifications___en___default",
    path: "/tester/notifications",
    meta: notificationsD1xObE4IlWMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/notifications.vue").then(m => m.default || m)
  },
  {
    name: "tester-notifications___en",
    path: "/en/tester/notifications",
    meta: notificationsD1xObE4IlWMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/notifications.vue").then(m => m.default || m)
  },
  {
    name: "tester-notifications___es",
    path: "/es/tester/notifications",
    meta: notificationsD1xObE4IlWMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/notifications.vue").then(m => m.default || m)
  },
  {
    name: "tester-splash___en___default",
    path: "/tester/splash",
    meta: splashe2VcRLohCoMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/splash.vue").then(m => m.default || m)
  },
  {
    name: "tester-splash___en",
    path: "/en/tester/splash",
    meta: splashe2VcRLohCoMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/splash.vue").then(m => m.default || m)
  },
  {
    name: "tester-splash___es",
    path: "/es/tester/splash",
    meta: splashe2VcRLohCoMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/splash.vue").then(m => m.default || m)
  },
  {
    name: "tester-subscriptions___en___default",
    path: "/tester/subscriptions",
    meta: subscriptionsaPxhT3o6pXMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "tester-subscriptions___en",
    path: "/en/tester/subscriptions",
    meta: subscriptionsaPxhT3o6pXMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: "tester-subscriptions___es",
    path: "/es/tester/subscriptions",
    meta: subscriptionsaPxhT3o6pXMeta || {},
    component: () => import("/vercel/path0/apps/be.unity.org/app/pages/tester/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/help",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/pray",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/today",
    component: component_45stub5X4Ei38PMg
  }
]