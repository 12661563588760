export default defineNuxtPlugin(() => {
  // Readability
  const { cookie, theme } = useColorScheme()

  if (cookie.value !== 'auto') {
    useHead({
      htmlAttrs: {
        'data-theme': theme,
      },
    })
  }
})
